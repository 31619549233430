import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import "./index.css";
import { Icon } from "react-icons-kit";
import { ic_arrow_drop_down } from 'react-icons-kit/md/ic_arrow_drop_down'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FrTextInput from "../FrTextInput";

// const onSearch = (value) => {
// alert('search:', value);
// };



const useStyles = makeStyles((theme) => ({
  margin: {
    marginRight: '6px',
    marginBottom: '15px',
    // padding: '5px',
    fontSize: '0.9rem',
    // borderRadius: 4,
    // width: 350,
  },
  label: {
    margin: '1.5px',
    marginLeft: '16px',
    backgroundColor: 'var(--cor-tema)',
    zIndex: 13,
    color: 'var(--cor-placeholder)',
    top: '-38px',
    padding: '5px',
    fontSize: '0.8rem',
    transition: '0.4s',
  },

}));


export default function CustomizedSelects(props) {
  const classes = useStyles();
  const [filtro, setFiltro] = useState('C');

  const onChange = (value) => {
    props.setValue(value)
  };

  const onClear = () => {
    console.log(props.reg)
    props.form ? document.getElementById(props.form) : (
      props.reg ? document.getElementById('regForm').reset() : document.getElementById('searchForm').reset()
    )
    
  };

  const handleChange = (e) => {
    if (e.target.name == 'filtroI')
      setFiltro(filtro == 'I' ? 'C' : 'I')
    if (e.target.name == 'filtroC')
      setFiltro(filtro == 'I' ? 'C' : 'I')
    if (e.target.name == 'filtroF')
      setFiltro(filtro == 'I' ? 'C' : 'I')

  };


  return (

    <FormControl className={classes.margin}>
      <InputLabel hidden={!props.value} htmlFor="demo-customized-select-native" className={classes.label}>{props.label}</InputLabel>

      <FrTextInput
        id={props.id}
        name={props.id}
        value={props.valor || undefined}
        hidden
      />

      <Select
        disabled={props.disabled}
        className='select'
        dropdownStyle={{ overflow: 'auto', zIndex: "100000" }}
        // labelInValue
        // suffixIcon={<Checkbox
        //   value={filtro}
        //   name='filtroI'
        //   id='filtroI'
        //   size='small'
        //   color="primary"
        //   checked={filtro == 'I'}
        //   onClick={handleChange}
        // />}
        showSearch
        allowClear
        placeholder={props.label}
        bordered={false}
        style={{
          width: props.width,
          border: '1px solid #389492',
          backgroundColor: 'var(--cor-tema)',
          color: 'var(--cor-placeholder)',
          borderRadius: 4
        }}
        size={'middle'}
        optionFilterProp="children"
        onChange={onChange}
        onClear={onClear}
        id={props.id}
        name={props.id}
        value={props.value || undefined}
        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
        hidden={props.hidden}
        // filterOption={(input, option) => (option?.label ?? '').toLowerCase().startsWith(input.toLowerCase())}


        // InputProps={{
        //   startAdornment: <InputAdornment position="start">
        //     <Checkbox
        //       value={filtro}
        //       name='filtroI'
        //       id='filtroI'
        //       size='small'
        //       color="primary"
        //       checked={filtro == 'I'}
        //       onClick={handleChange}
        //     />

        //   </InputAdornment>,
        // }}
        
        options={props.data}

      />

    </FormControl >
  )
};