import $ from 'jquery'
import FrLoading from '../components/FrLoading'
import moment from 'moment'

export function paramsToObject(entries) {
    let result = {}
    for (let entry of entries) { // each 'entry' is a [key, value] tupple
        const [key, value] = entry;
        // console.log(key, value)
        if (value.indexOf('[') > -1 && value.indexOf(']') > -1) {
            result[key] = JSON.parse(value)
        } else result[key] = value;
    }
    return result;
}

export function setMultiSelectFilter(filter, data) {
    return new Promise((resolve, reject) => {
        let result = []
        if (filter.length > 0) {
            data.map((item) => {
                if (filter.indexOf(item.value) > -1) return result.push(item)
            })
            resolve(result)
        }
        else resolve(null)
    })
}

export function getUserCode() {
    let auth = JSON.parse(localStorage['authcelg'] || JSON.stringify({ authenticated: false, user: 0 }))
    return auth.user
}

export function formToObject(formData) {
    let queryObject = {}
    formData.forEach(function (value, key) {
        if (value !== null && value !== '') {
            queryObject[key] = value;
        }
    });
    return queryObject
}

export function objectToQueryString(obj) {
    var str = Object.keys(obj).map(function (key) {
        if (Array.isArray(obj[key])) {
            console.log(obj[key])
            return key + '=[' + obj[key].map((item) => {
                return item.value
            }).join(',') + ']'
        }
        else if (obj[key]) return key + '=' + obj[key];
    }).join('&');
    return str
}

export function removeSpecialChar(value) {
    return value.replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '').toUpperCase()
}

export function onlyNumbers(value) {
    return (value || '').replace(/[^\d]+/g, '');
}

export function onBlurDecimal(e, qty, handleChange) {
    e.preventDefault();
    console.log(e.target.value)
    let value = e.target.value.replace(".", "").replace(",", ".")
    value = Number(value).toFixed(qty).replace(".", ",")
    console.log(value)
    e.target.value = value
    handleChange(e)
}

export function onBlurSeparator(e, handleChange) {
    e.preventDefault();
    console.log(e.target.value)
    let value = e.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    console.log(value)
    e.target.value = value
    handleChange(e)
}

export function validarCNPJ(cnpj) {
    cnpj = onlyNumbers(cnpj)
 
    if(cnpj === '') return false;

    console.log(cnpj)
     
    if (cnpj.length !== 14)
        return false;
 
    // Elimina CNPJs invalidos conhecidos
    if (cnpj === "00000000000000" || 
        cnpj === "11111111111111" || 
        cnpj === "22222222222222" || 
        cnpj === "33333333333333" || 
        cnpj === "44444444444444" || 
        cnpj === "55555555555555" || 
        cnpj === "66666666666666" || 
        cnpj === "77777777777777" || 
        cnpj === "88888888888888" || 
        cnpj === "99999999999999")
        return false;
         
    // Valida DVs
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0,tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
            pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0))
        return false;
         
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0,tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1))
          return false;
           
    return true;
    
}

export function validarCPF(cpf) {
    cpf = onlyNumbers(cpf)

    if (cpf === '') return false;
    if (
        !cpf ||
        cpf.length !== 11 ||
        cpf === "00000000000" ||
        cpf === "11111111111" ||
        cpf === "22222222222" ||
        cpf === "33333333333" ||
        cpf === "44444444444" ||
        cpf === "55555555555" ||
        cpf === "66666666666" ||
        cpf === "77777777777" ||
        cpf === "88888888888" ||
        cpf === "99999999999"
    ) {
        return false
    }
    var soma = 0
    var resto
    for (var i = 1; i <= 9; i++)
        soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i)
    resto = (soma * 10) % 11
    if ((resto === 10) || (resto === 11)) resto = 0
    if (resto !== parseInt(cpf.substring(9, 10))) return false
    soma = 0
    for (var i = 1; i <= 10; i++)
        soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i)
    resto = (soma * 10) % 11
    if ((resto === 10) || (resto === 11)) resto = 0
    if (resto !== parseInt(cpf.substring(10, 11))) return false
    return true
}

export function populateForm(frm, data) {
    $.each(data, function (key, value) {
        var $ctrl = $('[name=' + key + ']', frm);
        if ($ctrl.is('select')) {
            $("option", $ctrl).each(function () {
                // eslint-disable-next-line
                if (this.value === value) { this.selected = true; }
            });
        }
        else {
            // eslint-disable-next-line
            switch ($ctrl.attr("type")) {
                case "text": case "hidden": case "textarea": case "date":
                    $ctrl.val(value);
                    break;
                case "radio": case "checkbox":
                    $ctrl.each(function () {
                        // eslint-disable-next-line
                        if ($(this).attr('value') === value) { $(this).attr("checked", value); } else { $(this).attr("checked", null); }
                    });
                    break;
            }
        }
    });
};

export async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
}

export function isEmpty(obj) {
    for (var prop in obj) {
        if (obj.hasOwnProperty(prop))
            return false;
    }
    return true;
}

export function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    return age;
}


export function horaFinal(horaString, intervaloString) {
    var resultado = moment(horaString, 'hh:mm').add(intervaloString, 'minutes').format('hh:mm')
    return resultado
}


export function getFullAge(dateString) {
    var birthDate = new Date(dateString);
    let hoje = new Date();
    let dia_hoje = hoje.getDay();
    let mes_hoje = hoje.getMonth() + 1;
    let ano_hoje = hoje.getYear();

    let dia = birthDate.getDay();
    let mes = birthDate.getMonth() + 1;
    let ano = birthDate.getYear();


    let diferenca_dia = dia_hoje - dia;
    let diferenca_mes = mes_hoje - mes;
    let diferenca_ano = ano_hoje - ano;
    //alert(diferenca_dia+" "+diferenca_mes+" Ano = "+diferenca_ano+" ")
    if (diferenca_dia < 0) {
        diferenca_dia = 30 + diferenca_dia;
        diferenca_mes--;
    } else if (diferenca_mes < 0) {
        diferenca_mes = 12 + diferenca_mes;
        diferenca_ano--;
    }

    return diferenca_ano + ' anos ' + diferenca_mes + " meses " + diferenca_dia + ' dias';

}

export function swalCarregando(swal){
    swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
}


export function pegaDia(data, soData) {
    let dia = data.getDate();
    let diaSem = [
        function(){
            return 'Domingo'
        },
        function(){
            return 'Segunda-feira'
        },
        function(){
            return 'Terça-feira'
        },
        function(){
            return 'Quarta-feira'
        },
        function(){
            return 'Quinta-feira'
        },
        function(){
            return 'Sexta-feira'
        },
        function(){
            return 'Sábado'
        },

    ]
    if (dia < 10)
        dia = '0' + dia
    let mes = data.getMonth() + 1;
    if (mes < 10)
        mes = '0' + mes
    let ano = data.getFullYear();
    let diaSemana = data.getDay();
    // if (diaSemana == 0)
    //     diaSemana = 'Domingo'
    // else
    //     if (diaSemana == 1)
    //         diaSemana = 'Segunda-feira'
    //     else
    //         if (diaSemana == 2)
    //             diaSemana = 'Terça-feira'
    //         else
    //             if (diaSemana == 3)
    //                 diaSemana = 'Quarta-feira'
    //             else
    //                 if (diaSemana == 4)
    //                     diaSemana = 'Quinta-feira'
    //                 else
    //                     if (diaSemana == 5)
    //                         diaSemana = 'Sexta-feira'
    //                     else
    //                         if (diaSemana == 6)
    //                             diaSemana = 'Sábado'
    if (soData)
        return dia + '/' + mes + '/' + ano
    else
        return dia + '/' + mes + '/' + ano + '     -     ' + diaSem[diaSemana]()


}

export function dateSql(texto, entre) {
    let data = ''
    if (texto != null) {
        if (typeof entre !== 'undefined') {
            data = entre + texto.substr(0, 4) + '-' + texto.substr(5, 2) + '-' + texto.substr(8, 2) + entre
        } else {
            data = texto.substr(0, 4) + '-' + texto.substr(5, 2) + '-' + texto.substr(8, 2)
        }
    }
    return data;
}
