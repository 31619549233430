import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FrDatePicker from '../components/FrDatePicker'
import {colors} from '../utils/vars'
import FrameF2 from '../components/FrameF2'
import AutoComp from '../components/AutoComp'

import api from '../services/api'
import { paramsToObject, getUserCode, swalCarregando } from '../utils/functions'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
import consultaCep from '../services/consultaCep'
import {mPERC} from '../utils/masks'
import { Hidden } from '@material-ui/core';
import DadosCartao from '../components/DadosCartao';

const swal = withReactContent(Swal)

function Associado_Plano(props) {
  const [data, setData] = useState({})
  const [dataPrimeira, setDataPrimeira] = useState()
  const [updateType, setUpdateType] = useState(false)
  const [comboTme, setComboTme] = useState([{ value: '', display: '' }])
  const [comboAss, setComboAss] = useState([{ value: '', display: '' }])
  const [comboFpg, setComboFpg] = useState([{ value: '', display: '' }])
  const [valor, setValor] = useState('')
  const [qtdParcela, setQtdParcela] = useState()
  const [comboBco, setComboBco] = useState([{ value: '', display: '' }])
  const [comboSas, setComboSas] = useState([{ value: '', display: '' }])
  const [comboPagamento, setComboPagamento] = useState([{ value: 'A', display: 'Anual' }, { value: 'M', display: 'Mensal' }])
  const [comboPeridiocidade, setComboPeridiocidade] = useState([{ value: 'M', display: 'Mensal' }, { value: 'A', display: 'Anual' }, { value: 'T', display: 'Todos' }])
  const [comboSit, setComboSit] = useState([{ value: 'S', display: 'Ativo' }, { value: 'N', display: 'Inativo' }])
  const [comboDia, setComboDia] = useState([{ value: '', display: '' }])
  const [enableEdit, setEnableEdit] = useState('N')
  const [fk_bco, setFk_bco] = useState('')
  const [agencia, setAgencia] = useState('')
  const [conta, setConta] = useState('')
  const [agencia_digito, setAgencia_digito] = useState('')
  const [conta_digito, setConta_digito] = useState('')
  const [nome_cartao, setNome_cartao] = useState('')
  const [fpg, setFpg] = useState('')
  const [ass, setAss] = useState('')

  const [comboBac, setComboBac] = useState([{ value: '', display: '' }])
  const [fk_bac, setFk_bac] = useState('')
  const [mes_valid_cartao, setMes_valid_cartao] = useState('')
  const [numero_cartao, setNumero_cartao] = useState('')
  const [codigo_seguranca, setCodigo_seguranca] = useState('')
  const [ano_validade_Cartao, setAno_validade_Cartao] = useState('')
  
  const [comboMes, setComboMes] = useState(
    [{value: '01', display: 'Janeiro'},
     {value: '02', display: 'Fevereiro'},
     {value: '03', display: 'Março'},
     {value: '04', display: 'Abril'},
     {value: '05', display: 'Maio'},
     {value: '06', display: 'Junho'},
     {value: '07', display: 'Julho'},
     {value: '08', display: 'Agosto'},
     {value: '09', display: 'Setembro'},
     {value: '10', display: 'Outubro'},
     {value: '11', display: 'Novembro'},
     {value: '12', display: 'Dezembro'},])
  const [comboAno, setComboAno] = useState(
      [{value: '22', display: '2022'},
       {value: '23', display: '2023'},
       {value: '24', display: '2024'},
       {value: '25', display: '2025'},
       {value: '26', display: '2026'},
       {value: '27', display: '2027'},
       {value: '28', display: '2028'},
       {value: '29', display: '2029'},
       {value: '30', display: '2030'},
       {value: '31', display: '2031'},
       {value: '32', display: '2032'},
       {value: '33', display: '2033'},])

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
      let codigo = props.pk_cad ? props.pk_cad : query.codigo
      
      setComboTme(await api.getComboData('tipo_mensalidade'))
      setComboSas(await api.getComboData('situacao_associado'))
      setComboFpg(await api.getComboData('forma_pagamento'))
      setComboAss(await api.getComboData('associados'))
      setComboBac(await api.getComboData('bandeira_cartao'))
      setComboBco(await api.getComboData('bancos'))
      let auxDia=[]
      let i=1
      while(i<=31){
        auxDia.push({value: i, display: i})
        i=i+1
        if(i==31){
          setComboDia(auxDia)
        }
      }


      if (codigo > 0) {
        setEnableEdit(await api.getAcessos('Termo de Associacao', 'Alteracao'))
      } else {
        setEnableEdit(await api.getAcessos('Termo de Associacao', 'Inclusao'))
      }


      if (codigo > 0) {
        setUpdateType(true)
        api.get('/associado_plano?pk_asp=' + codigo).then(r => {
          if (r.data[0]) {
            let auxValues = {...r.data[0]}
            auxValues.tipo_pgto = (auxValues.pgto_anual == 'S' ? 'A' : 'M')
            auxValues.data_associacao = auxValues.data_associacao.split("T")[0]
            auxValues.data_prev_graduacao= (r.data[0].data_prev_graduacao ? r.data[0].data_prev_graduacao.split("T")[0] : '')
            auxValues.data_primeira_mensalidade= (r.data[0].data_primeira_mensalidade ? r.data[0].data_primeira_mensalidade.split("T")[0] : '')
            setData(auxValues)
            api.get('/associado_plano/pegaDadosFinanceiros?fk_ass='+auxValues.fk_ass).then(res=>{
              console.log(res.data[0])
              setFk_bco(res.data[0].fk_bco)
              setConta(res.data[0].conta)
              setAgencia(res.data[0].agencia)
              setConta_digito(res.data[0].ditigo_conta)
              setAgencia_digito(res.data[0].ditigo_agencia)
              setFk_bac(res.data[0].fk_bac)
              setNome_cartao(res.data[0].nome_cartao)
              setMes_valid_cartao(res.data[0].mes_valid_cartao)
              setNumero_cartao(res.data[0].numero_cartao)
              setCodigo_seguranca(res.data[0].codigo_seguranca)
              setAno_validade_Cartao(res.data[0].ano_validade_Cartao)
              swal.close()
            })
            
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
          }
        })
      } else swal.close()
    }

    fetchData()
  }, [])

  function consistData(data) {
    let required = [
      'fk_ass',
      'fk_tme',
      'data_associacao',
      // 'data_primeira_mensalidade',
      'tipo_pgto',
      // 'valor_mensalidade',
      'ativo',
    ]

    if(valor > 0){
      required.push('fk_fpg')
      required.push('data_primeira_mensalidade')
    }

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if(fieldName == 'fk_ass') { fieldName= 'Associado'}
      if(fieldName == 'fk_tme') { fieldName= 'Tipo Mensalidade'}
      if(fieldName == 'fk_fpg') { fieldName= 'Forma de Pagamento'}
      if(fieldName == 'data_associacao') { fieldName= 'Data de Associação'}
      if(fieldName == 'data_primeira_mensalidade') { fieldName= 'Data Primeira Mensalidade'}
      if(fieldName == 'tipo_pgto') { fieldName= 'Peridiocidade'}
      if(fieldName == 'valor_mensalidade') { fieldName = 'Valor Mensalidade'}
      if(fieldName == 'ativo') { fieldName = 'Status'}


      if (!data[curr]) return errors.push(fieldName)
    })

    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      
      title: `<h2 style='color:${colors.info900}'>ATENÇÃO</h2>`,
      html: `<p style='color:${colors.info900}'>Deseja salvar ${updateType ? 'as alterações' : 'a inclusão'} do registro?</p>`,
      // html: `<p style='color:${colors.info900}'>Deseja salvar ${updateType ? 'as alterações' : 'a inclusão'} do registro?</p>`,
      color: colors.info900,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: colors.success500,
      cancelButtonColor: colors.danger500,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        
        swalCarregando(swal)
        const auxValues = { ...data };
        auxValues.host = window.location.href;
        auxValues.fk_bco = fk_bco
        auxValues.conta = conta
        auxValues.agencia = agencia
        auxValues.conta_digito = conta_digito
        auxValues.agencia_digito = agencia_digito
        auxValues.fk_bac = fk_bac
        auxValues.numero_cartao = numero_cartao
        auxValues.mes_valid_cartao = mes_valid_cartao
        auxValues.ano_validade_Cartao = ano_validade_Cartao
        auxValues.codigo_seguranca = codigo_seguranca
        auxValues.nome_cartao = nome_cartao
        auxValues.fk_usu = getUserCode()
        auxValues.valor = valor
        auxValues.envia_email = 'N'
        auxValues.qtd_parcelas = qtdParcela
        console.log(auxValues.desconto)
        let errors = consistData(auxValues)
        console.log(errors.length)
        if (errors.length === 0) {
          if (updateType) {
            api.post('/associado_plano/edit', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: `<h2 style='color: ${colors.info900}'>Registro Alterado<h2>`,
                  html: `<p style='color: ${colors.info900}'>O registro foi alterado com sucesso!</p>`,
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
                  html: `<p style='color: ${colors.info900}'></p>`,
                  
                  icon: 'warning',
                })
              }
            })
          } else {
            try {
              api.get('/associado_plano/associado_ativo?fk_ass='+ auxValues.fk_ass).then(r => {
                console.log(r)
                if (r.data == 'associado ativo') {
                    console.log('oi')
                    swal.fire({
                      title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
                      text: "Já existe associação ativa para esse associado!",
                      icon: 'warning',
                    })
                  }else {
                    swal.fire({
                      title: `<h2 style='color:${colors.info900}'>ATENÇÃO</h2>`,
                      html: `<p style='color:${colors.info900}'> Deseja notificar o associado?</p>`,
                      // html: `<p style='color:${colors.info900}'>Deseja salvar ${updateType ? 'as alterações' : 'a inclusão'} do registro?</p>`,
                      color: colors.info900,
                      icon: 'question',
                      showCancelButton: true,
                      confirmButtonColor: colors.success500,
                      cancelButtonColor: colors.danger500,
                      confirmButtonText: 'Sim',
                      cancelButtonText: 'Não'
                    }).then((result) => {
                      console.log(result)
                      if(result.value){
                        auxValues.envia_email='S'
                      }
                      api.post('/associado_plano/add', auxValues).then(r =>{
                        if(r.status == 200){
                          swal.fire({
                            title: 'Registro Adicionado',
                            text: "O registro foi adicionado com sucesso!",
                            icon: 'success',
                          }).then((result) => {
                            if (result.value) {
                              window.history.back()
                            }  else {
                              swal.fire({
                                title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
                                text: "O registro não foi adicionado!",
                                icon: 'warning',
                              })
                            }
                          })
    
                          
                        }
                      })
                    })
                  }
                  
               
              })
            } catch {
              swal.fire({
                title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
                text: "O registro não foi adicionado!",
                icon: 'warning',
              })

            }

          }
        } else {
          // swal.close()
          console.log(errors)
          swal.fire({
            title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
            html: <p style={{color: colors.info900}}>Verifique {errors.length>1?'os campos': 'o campo'} {errors.join(', ')}</p>,
            icon: 'warning',
          })
        }
      }
    })
  }

  useEffect(()=>{
    let auxData={...data}
    api.get('/tipo_mensalidade?pk_tme='+data.fk_tme).then(r => {
      auxData.valor_mensalidade= r.data[0].valor
      setData(auxData)
    })
  },
  [data.fk_tme])

  const handleCep = (e) => {
    e.preventDefault()
    const auxValues = { ...data };
    swal.fire({
      html: <FrLoading text="Carregando..." />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    })
    
    consultaCep((e.target.value).replace('.','').replace('-','')+'/json').then(r =>{
      if(!r.data.erro){
        swal.close()
        console.log(r.data)
        auxValues.endereco = r.data.logradouro
        auxValues.complemento = r.data.complemento
        auxValues.nr_endereco = r.data.numero

        setData(auxValues)
      } else{
        swal.close()
        swal.fire('CEP Inválido!', 'CEP Não encontrado nos registros!', 'warning')
      }
    })

  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: `<h2 style='color:${colors.info900}'>ATENÇÃO</h2>`,
      html: `<p style='color: ${colors.info900}'>Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?<p>`,
      icon: 'warning',
      color: colors.info900,
      showCancelButton: true,
      confirmButtonColor: colors.success500,
      cancelButtonColor: colors.danger500,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    // auxValues[e.target.name] = e.target.value;
    switch (e.target.name) {
      case 'desconto':
        auxValues[e.target.name] = mPERC(e.target.value);
        break;

        case 'fk_bco':
          setFk_bco(e.target.value)
          break;
        case 'agencia':
          setAgencia(e.target.value)
          break;
        case 'conta':
          setConta(e.target.value)
          break;
        case 'agencia_digito':
          setAgencia_digito(e.target.value)
          break;
        case 'conta_digito':
          setConta_digito(e.target.value)
          break;
        case 'fk_bac':
          setFk_bac(e.target.value)
          break;
        case 'mes_valid_cartao':
          setMes_valid_cartao(e.target.value)
          break;
        case 'numero_cartao':
          setNumero_cartao(e.target.value)
          break;  
        case 'ano_validade_Cartao':
          setAno_validade_Cartao(e.target.value)
          break;
        case 'codigo_seguranca':
          setCodigo_seguranca(e.target.value)
          break; 
        case 'fk_ass':   
        case 'ass': {
          auxValues['fk_ass'] = e.target.value;
          setAss(e.target.value)
          break
        }
      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };

  useEffect(() => {
    const fetchAss = async () => {

      setComboAss(await api.getComboData('associados'))

      const auxValues = { ...data };

      if (ass > 0) {
        auxValues['fk_ass'] = ass;

        setData(auxValues);
      }
    }
    fetchAss()
  }, [ass])

  const handleBlur = (e) => {
    e.preventDefault()
    console.log('joke')
    console.log(e.target.value)
    let data = new Date()
    let dataMensalidade = new Date(e.target.value)
    console.log(data.toLocaleString().substr(0, 10))
    data =(data.toLocaleString().substr(6,4) + '-' + data.toLocaleString().substr(3,2) + '-' + data.toLocaleString().substr(0,2))
    console.log(Date.parse(e.target.value), Date.parse(data))
    console.log(dataMensalidade, data.toLocaleString().substr(0, 10))
    if (Date.parse(e.target.value)< Date.parse(data)){
      swal.fire({
        title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
        html: `<p style='color: ${colors.info900}'>Data não pode ser anterior a hoje</p>`,
        icon: 'warning',
      }).then(r => {
        document.getElementById('data_primeira_mensalidade').focus()
      })
    }

  }

  useEffect(()=> {
    if(data.data_primeira_mensalidade){
      let date = data.data_primeira_mensalidade.toLocaleString().substr(0,10)
      console.log(date)
      setDataPrimeira(date.substr(8,2)+'/'+date.substr(5,2)+'/'+date.substr(0,4))
    } else {
      setDataPrimeira(null)
    }

  }, [data.data_primeira_mensalidade])

  useEffect (() => {
    if(data.fk_tme>0){
      swal.fire({
       
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      api.get('tipo_mensalidade?pk_tme='+data.fk_tme).then(r=>{
        if(r.data.length>0){

          data.desconto? console.log(data.desconto): console.log('nao passou')
          console.log((r.data[0].valor))
          if(r.data[0].valor>0){
            if((data.desconto)){
              console.log((r.data[0].valor.toFixed(2) - (Number(data.desconto.toString().replace(',','.'))) /100 ).toFixed(2))
              console.log((Number(data.desconto.toString().replace(',','.'))) /100 )

              setValor('R$ '+(r.data[0].valor.toFixed(2) - (((Number(data.desconto.toString().replace(',','.'))) /100)*r.data[0].valor ) ).toFixed(2))
            } else{
              setValor('R$ '+r.data[0].valor.toFixed(2))
            }
            
          } else {
            setValor('R$ 0.00')
          }
          if(r.data[0].qtd_parcelas>0){
            setQtdParcela(r.data[0].qtd_parcelas + 'X')
            
          } else {
            setQtdParcela(null)
          }
          swal.close()
          console.log(r.data[0].valor)
        }
      })
    } else {
      setValor('R$ 0.00')
      setQtdParcela(null)
    }

  }, [data.fk_tme])

  const handleBlurDesconto = (e) => {
    e.preventDefault()
    if(data.fk_tme>0){
      swal.fire({
        
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      api.get('tipo_mensalidade?pk_tme='+data.fk_tme).then(r=>{
        if(r.data.length>0){

          data.desconto? console.log(data.desconto): console.log('nao passou')
          console.log((r.data[0].valor))
          if(r.data[0].valor>0){
            if((data.desconto)){
              console.log((r.data[0].valor.toFixed(2) - (Number(data.desconto.toString().replace(',','.'))) /100 ).toFixed(2))
              console.log((Number(data.desconto.toString().replace(',','.'))) /100 )

              setValor('R$ '+(r.data[0].valor.toFixed(2) - (((Number(data.desconto.toString().replace(',','.'))) /100)*r.data[0].valor ) ).toFixed(2))
            } else{
              setValor('R$ '+r.data[0].valor.toFixed(2))
            }
            
          } else {
            setValor('R$ 0.00')
          }
          if(r.data[0].qtd_parcelas>0){
            setQtdParcela(r.data[0].qtd_parcelas + 'X')
            
          } else {
            setQtdParcela(null)
          }
          swal.close()
          console.log(r.data[0].valor)
        }
      })
    } else {
      setValor('R$ 0.00')
      setQtdParcela(null)
    }
  }

  useEffect(() => {
    if ((data.fk_fpg == 2 || data.fk_fpg == 4) && data.fk_ass){
      swalCarregando(swal)
      if(data.fk_fpg == 4){
        setFpg('C')
      } else if (data.fk_fpg == 2){
        setFpg('B')
      }
      api.get('/associado_plano/pegaDadosFinanceiros?fk_ass='+data.fk_ass).then(res=>{
        console.log(res.data[0])
        setFk_bco(res.data[0].fk_bco)
        setConta(res.data[0].conta)
        setAgencia(res.data[0].agencia)
        setConta_digito(res.data[0].conta)
        setAgencia_digito(res.data[0].agencia)
        setFk_bac(res.data[0].fk_bac)
        setMes_valid_cartao(res.data[0].mes_valid_cartao)
        setNumero_cartao(res.data[0].numero_cartao)
        setCodigo_seguranca(res.data[0].codigo_seguranca)
        setAno_validade_Cartao(res.data[0].ano_validade_Cartao)              
        swal.close()
      })
    }
  }, [data.fk_ass, data.fk_fpg])


  function handleBandeira (e) {
    console.log(e.issuer)
    console.log(comboBac)
    let value = comboBac.filter((i)=> i.display == e.issuer.toUpperCase())
    if(value.length == 1){
      setFk_bac(value[0].value)
    } else {
      setFk_bac(4)
    }
    console.log(comboBac.filter((i)=> i.display == e.issuer.toUpperCase()))
  }

  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>{updateType ? 'Edição' : 'Inclusão'} de Associação</h3>
          <UserOptions temaEscuro={props.temaEscuro} setTemaEscuro={props.setTemaEscuro} />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit}>

        <div class="row" style={{marginLeft: '0px'}}>
          <AutoComp
            reg
            value={data.fk_ass || ""}
            valor={ass}
            width={425}
            name="fk_ass"
            id="fk_ass"
            label='Associado'
            data={comboAss}
            setValue={setAss}
          />
          <FrameF2 tipoModal={'Associados'} data={data} ass={ass} setAss={setAss} ></FrameF2>
        
        </div>
        
          <FrSelect
            value={data.fk_tme || ''}
            onChange={handleChange}
            name="fk_tme"
            id="fk_tme"
            label='Tipo Mensalidade'
            data={comboTme}
            style={{ width: 190 }}
          />
          

          <FrDatePicker
            name="data_associacao"
            id="data_associacao"
            color="#389492"
            label="Data de Associação"
            variant="outlined"
            size="small"
            style={{ width: 180 }}
            value={(data.data_associacao || '').split("T")[0]}
            onChange={handleChange}
          />

          <FrDatePicker
            name="data_primeira_mensalidade"
            id="data_primeira_mensalidade"
            color="#389492"
            label="Data Primeira Mensalidade"
            variant="outlined"
            size="small"
            style={{ width: 180 }}
            value={(data.data_primeira_mensalidade || '').split("T")[0]}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <FrSelect
            value={data.dia_preferencial_pgto || ''}
            onChange={handleChange}
            name="dia_preferencial_pgto"
            id="dia_preferencial_pgto"
            label='Dia Pagamento'
            data={comboDia}
            style={{ width: 190 }}
          />  

          <FrSelect
            value={data.tipo_pgto || ''}
            onChange={handleChange}
            name="tipo_pgto"
            id="tipo_pgto"
            label='Peridiocidade'
            data={comboPagamento}
            style={{ width: 190 }}
          />      

          <FrTextInput
            maxLength='6'
            value={data.desconto || ''}
            onChange={handleChange}
            name="desconto"
            id="desconto"
            color="#389492"
            label={'Desconto (%)'}
            variant="outlined"
            size="small"
            onBlur={handleBlurDesconto}
            style={{ width: 120 }}
          />  

          <FrDatePicker
            name="data_prev_graduacao"
            id="data_prev_graduacao"
            color="#389492"
            label="Previsão graduação"
            variant="outlined"
            size="small"
            style={{ width: 180 }}
            value={(data.data_prev_graduacao || '').split("T")[0]}
            onChange={handleChange}
          />

          <FrTextInput
            maxLength='300'
            value={data.observacoes || ''}
            onChange={handleChange}
            name="observacoes"
            id="observacoes"
            color="#389492"
            label={'Observações'}
            variant="outlined"
            size="small"
            style={{ width: 500 }}
          />  
          
          {/* <FrTextInput
            maxLength='5'
            value={data.valor_mensalidade || ''}
            onChange={handleChange}
            name="valor_mensalidade"
            id="valor_mensalidade"
            color="#389492"
            label={'Valor'}
            variant="outlined"
            size="small"
            required
            style={{ width: 120 }}
          />      */}

          <FrSelect
            value={data.ativo || ''}
            onChange={handleChange}
            name="ativo"
            id="ativo"
            label='Status'
            data={comboSit}
            style={{ width: 150 }}
            disabled={updateType}
          />    
          <br/>

          <FrSelect
            value={data.fk_fpg || ''}
            onChange={handleChange}
            name="fk_fpg"
            id="fk_fpg"
            label='Forma de Pagamento'
            data={comboFpg}
            style={{ width: 190 }}
          />
          <br/>
          {updateType && data.ativo == 'N' ? 
          <FrTextInput
            maxLength='100'
            value={data.motivo_cancelamento || ''}
            onChange={handleChange}
            name="motivo_cancelamento"
            id="motivo_cancelamento"
            color="#389492"
            label={'Motivo Cancelamento'}
            variant="outlined"
            size="small"
            required
            style={{ width: 320 }}
          /> : <></> }
          {updateType && data.ativo == 'N' ? 
            <FrDatePicker
            name="data_cancelamento"
            id="data_cancelamento"
            color="#389492"
            label="Data Cancelamento"
            variant="outlined"
            size="small"
            disabled
            style={{ width: 180 }}
            value={(data.data_cancelamento || '').split("T")[0]}
            onChange={handleChange}
          /> : <></> }
{/* 
<div hidden={!updateType || fpg == 'N'}>
            Dados Financeiros
            <br/>
            <br/>
            <FrSelect
              value={data.fk_bco || ''}
              onChange={handleChange}
              name="fk_bco"
              id="fk_bco"
              hidden={fpg != 'B'}
              label='Banco'
              data={comboBco}
              
              style={{ width: 200 }}
            />
            <FrTextInput
              maxLength='45'
              value={data.agencia || ''}
              onChange={handleChange}
              name="agencia"
              id="agencia"
              color="#389492"
              label="Agência"
              variant="outlined"
              hidden={fpg != 'B'}
              size="small"
              required={data.fk_bco?.length>0}
              style={{ width: 140 }}
            />
            <FrTextInput
              maxLength='45'
              value={data.conta || ''}
              onChange={handleChange}
              name="conta"
              id="conta"
              color="#389492"
              label="Conta"
              variant="outlined"
              hidden={fpg != 'B'}
              size="small"
              required={data.fk_bco?.length>0}
              style={{ width: 210 }}
            />
            <br/>
            <FrSelect
              value={data.fk_bac || ''}
              onChange={handleChange}
              name="fk_bac"
              id="fk_bac"
              label='Bandeira Cartão'
              required
              hidden={fpg != 'C'}
              data={comboBan}
              style={{ width: 170 }}
            />
            <FrTextInput
              maxLength='20'
              value={data.numero_cartao || ''}
              onChange={handleChange}
              name="numero_cartao"
              id="numero_cartao"
              color="#389492"
              label="Número Cartão"
              variant="outlined"
              hidden={fpg != 'C'}
              required
              size="small"
              style={{ width: 270 }}
            />
            <FrSelect
              value={data.mes_valid_cartao || ''}
              onChange={handleChange}
              name="mes_valid_cartao"
              id="mes_valid_cartao"
              label='Mês Validade'
              hidden={fpg != 'C'}
              data={comboMes}
              required
              style={{ width: 140 }}
            />
            <FrSelect
              value={data.ano_validade_Cartao || ''}
              onChange={handleChange}
              name="ano_validade_Cartao"
              id="ano_validade_Cartao"
              label='Ano Validade'
              hidden={fpg != 'C'}
              required
              data={comboAno}
              style={{ width: 140 }}
            />
            <FrTextInput
              maxLength='10'
              value={data.codigo_seguranca || ''}
              onChange={handleChange}
              name="codigo_seguranca"
              id="codigo_seguranca"
              color="#389492"
              label="Código Segurança"
              variant="outlined"
              hidden={fpg != 'C'}
              required
              size="small"
              style={{ width: 160 }}
            />
            <FrTextInput
              maxLength='100'
              value={data.token_cartao || ''}
              onChange={handleChange}
              name="token_cartao"
              id="token_cartao"
              color="#389492"
              label="Token Cartão"
              variant="outlined"
              required
              size="small"
              hidden={true}
              style={{ width: 280 }}
            />

          </div> */}
          
          {data.fk_fpg == 2 ? <>
            <div hidden={data.fk_fpg != 2}>
            Dados Financeiros
            <br/>
            <br/>
            <FrSelect
              value={fk_bco || ''}
              onChange={handleChange}
              name="fk_bco"
              id="fk_bco"
              label='Banco *'
              data={comboBco}
              
              style={{ width: 200 }}
            />
            <FrTextInput
              maxLength='45'
              value={agencia || ''}
              onChange={handleChange}
              name="agencia"
              id="agencia"
              color="#389492"
              label="Agência"
              variant="outlined"
              size="small"
              required
              style={{ width: 140 }}
            />
            <FrTextInput
              maxLength='45'
              value={agencia_digito || ''}
              onChange={handleChange}
              name="agencia_digito"
              id="agencia_digito"
              color="#389492"
              label="Dígito Agência"
              variant="outlined"
              size="small"
              required
              style={{ width: 140 }}
            />
            <FrTextInput
              maxLength='45'
              value={conta || ''}
              onChange={handleChange}
              name="conta"
              id="conta"
              color="#389492"
              label="Conta"
              variant="outlined"
              size="small"
              required
              style={{ width: 210 }}
            />
            <FrTextInput
              maxLength='45'
              value={conta_digito || ''}
              onChange={handleChange}
              name="conta_digito"
              id="conta_digito"
              color="#389492"
              label="Dígito Conta"
              variant="outlined"
              size="small"
              required
              style={{ width: 210 }}
            />
            </div>
          
          </>:
          <>
          </>}

          
          {data.fk_fpg == 4 ? <>
            <div hidden={data.fk_fpg != 4}>
            Dados Financeiros
            <br/>
            <br/>
            <DadosCartao
              comboBac={comboBac}
              fk_bac={fk_bac}
              codigo_seguranca={codigo_seguranca}
              numero_cartao={numero_cartao}
              ano_validade_Cartao={ano_validade_Cartao}
              mes_valid_cartao={mes_valid_cartao}
              nome_cartao={nome_cartao}
              setFk_ban={setFk_bac}
              setCodigo_seguranca={setCodigo_seguranca}
              setNumero_cartao={setNumero_cartao}
              setAno_validade_Cartao={setAno_validade_Cartao}
              setMes_valid_cartao={setMes_valid_cartao}
              setNome_cartao={setNome_cartao}
              fpg={fpg}
              callback={handleBandeira}
            />
            {/* <FrSelect
              value={fk_bac || ''}
              onChange={handleChange}
              name="fk_bac"
              id="fk_bac"
              label='Bandeira Cartão'
              required
              data={comboBan}
              style={{ width: 170 }}
            />
            <FrTextInput
              maxLength='20'
              value={numero_cartao || ''}
              onChange={handleChange}
              name="numero_cartao"
              id="numero_cartao"
              color="#389492"
              label="Número Cartão"
              variant="outlined"
              required
              size="small"
              style={{ width: 270 }}
            />
            <FrSelect
              value={mes_valid_cartao || ''}
              onChange={handleChange}
              name="mes_valid_cartao"
              id="mes_valid_cartao"
              label='Mês Validade'
              data={comboMes}
              required
              style={{ width: 140 }}
            />
            <FrSelect
              value={ano_validade_Cartao || ''}
              onChange={handleChange}
              name="ano_validade_Cartao"
              id="ano_validade_Cartao"
              label='Ano Validade'
              required
              data={comboAno}
              style={{ width: 140 }}
            />
            <FrTextInput
              maxLength='10'
              value={codigo_seguranca || ''}
              onChange={handleChange}
              name="codigo_seguranca"
              id="codigo_seguranca"
              color="#389492"
              label="Código Segurança"
              variant="outlined"
              required
              size="small"
              style={{ width: 160 }}
            /> */}
            </div>
          
          </>:
          <>
          </>}          

          <br/>
          <div>
            {/* {valor && qtdParcela? */}
              <div style={{display: 'flex', width: 306, justifyContent: 'space-between', color:'var(--cor-fonte-hover)'}}>
                <p >Mensalidade:</p>
                <p>{(qtdParcela ? qtdParcela : '') + ' ' + valor}</p>
              </div>
             {/* :
               <>
               </>   
            } */}

            <br/>
            {dataPrimeira ? 
            
            <p>{'Primeira Parcela com vencimento em '+ dataPrimeira} </p>
             :
             <></> 
          }
          

          </div>
             
        </form>
        <div className="regButtonsDiv">
          {enableEdit === 'S' ?
            (<button onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Associado_Plano;
