import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import AutoComp from '../components/AutoComp'

import NavBar from '../components/NavBar'
import FrTable from '../components/FrTable'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import FrDatePicker from '../components/FrDatePicker'
import UserOptions from '../components/UserOptions'
import Dialog from '@material-ui/core/Dialog';
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'


import api from '../services/api'
import {mCPF, mCNPJ} from '../utils/masks'
import { paramsToObject, formToObject, populateForm, objectToQueryString,swalCarregando, getUserCode } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';
import { colors } from '@material-ui/core';
import { get } from 'jquery';

const swal = withReactContent(Swal)

function Associado_Plano(props) {
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [ass, setAss] = useState()
  const [enableAdd, setenableAdd] = useState('N')
  const [enableEdit, setenableEdit] = useState('N')
  const [enableDel, setenableDel] = useState('N')
  const [selected, setSelected] = useState([])
  const [ano, setAno] = useState()
  const [showModal, setShowModal] = useState(false)
  const [comboTme, setComboTme] = useState([{ value: '', display: '' }])
  const [comboAno, setComboAno] = useState([{ value: '', display: '' }])
  const [comboAss, setComboAss] = useState([{ value: '', display: '' }])
  const [comboFpg, setComboFpg] = useState([{ value: '', display: '' }])
  const [comboSas, setComboSas] = useState([{ value: '', display: '' }])
  const [comboPeridiocidade, setComboPeridiocidade] = useState([{ value: 'M', display: 'Mensal' }, { value: 'A', display: 'Anual' }, { value: 'T', display: 'Todos' }])
  const [comboSit, setComboSit] = useState([{ value: 'S', display: 'Ativo' }, { value: 'N', display: 'Inativo' }])
  const [motivo, setMotivo] = useState(null)
  const [dataCancelamento, setDataCancelamento] = useState(null)

  const columns = [
    { id: 'associado', numeric: false, label: 'Associado', align: 'left', minWidth: 200 },
    { id: 'formatdata_associacao', numeric: false, label: 'Data Associação', align: 'left', minWidth: 200 },
    { id: 'tipo_mensalidade', numeric: false, label: 'Tipo Mensalidade', align: 'left', minWidth: 200 },
    { id: 'forma_pagamento', numeric: false, label: 'Forma Pagamento', align: 'left', minWidth: 150 },
    { id: 'status', numeric: false, label: 'Situação', align: 'left', minWidth: 150 },

  ]

  useEffect(() => {
    const fetchData = async () => {
      swalCarregando(swal)

      setComboTme(await api.getComboData('tipo_mensalidade'))
      setComboSas(await api.getComboData('situacao_associado'))
      setComboFpg(await api.getComboData('forma_pagamento'))
      setComboAss(await api.getComboData('associados'))
      setComboAno([{ value: '2023', display: '2023' },
                   { value: '2024', display: '2024' },
                   { value: '2025', display: '2025' },
                   { value: '2026', display: '2026' },
                   { value: '2027', display: '2027' },
                   { value: '2028', display: '2028' },
                   { value: '2029', display: '2029' },
                   { value: '2030', display: '2030' },
                   { value: '2031', display: '2031' },
                   { value: '2032', display: '2032' }, 
                  ])

      setenableAdd(await api.getAcessos('Termo de Associacao', 'Inclusao'))
      setenableEdit(await api.getAcessos('Termo de Associacao', 'Alteracao'))
      setenableDel(await api.getAcessos('Termo de Associacao', 'Exclusao'))


      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams) 
      if (query.fk_ass)
        setAss(query.fk_ass)
      setFilter(query)
      populateForm(document.getElementById('searchForm'), query)

      swal.close()
      if (query.buscar === 'true') {
        let event = new Event('build');
        getData(event)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    const fetchMdc = async () => {
      const auxValues = { ...filter };
      auxValues["fk_ass"] = ass;
      auxValues["associado"] = ''
      if (ass > 0) {
        await api.get("/associados?pk_ass=" + ass).then((r) => {
          if (r.data[0])
            auxValues["associado"] = r.data[0].nome;
        });
      }
      setFilter(auxValues);
    };
    fetchMdc();
  }, [ass]);

  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    // if (!isEmpty(queryObject)) {
    if (true) {
      queryObject.buscar = true
      setFiltered(true)
      window.history.replaceState({ filtered: true }, 'filter', "/celg/associado_plano?" + objectToQueryString(queryObject));
      swal.fire({
        html: <FrLoading text="Buscando dados..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      api.get('/associado_plano', { params: queryObject }).then(r => {
        setData(r.data)
        console.log(r.data)
        swal.close()
      })
    } else {
      swal.fire('Nenhum filtro informado!', 'Informe ao menos um filtro para realizar a busca.', 'warning')
    }
  }

  const cancelarAssociacao = (e) => {
    // if(s == 'Aberto'){
      setShowModal(false)
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      api.post('/associado_plano/cancelarPlano', {pk: selected, data: dataCancelamento, motivo: motivo, usu: getUserCode()}).then(res=>{
        console.log(res)
        if(res.status == 200){

          swal.fire('Sucesso!', 'Associaciação cancelada', 'success').then(r =>{
            window.location.reload()
          })
        }else {
          swal.fire('Erro!', 'Falha ao cancelar associação.', 'warning')
        }
      })

    // } else {
    //   swal.fire('Erro!', 'Só é possível baixar boleto aberto.', 'warning')
    // }
  }

  function clearForm(e) {
    e.preventDefault();
    console.log('limpa');
    window.history.replaceState({ filtered: false }, 'filter', '/celg/associado_plano');
    document.getElementById('searchForm').reset()
    setFilter({})
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    console.log(auxValues)
    switch (e.target.name) {
      case 'ano':
        setAno(e.target.value);
        break;
      default: auxValues[e.target.name] = e.target.value;
    }
    setFilter(auxValues);
  };

  
  function escondeModal(e){
    e.preventDefault()
    setMotivo('')
    setDataCancelamento(null)
    setShowModal(false)
  }

  const mostraModal= (e) => {
    e.preventDefault()
    console.log(selected)
    setShowModal(true)
  }

  const handleChangeModal = (e) => {
    if(e.target.name == 'ano'){
      setAno(e.target.value)

    }
    if(e.target.name == 'motivo'){
      setMotivo(e.target.value)
    }
    if(e.target.name == 'cancelamento'){
      setDataCancelamento(e.target.value)
    }

  }

  useEffect(() => {
    console.log(ano)
  },[ano])

  // const geraAnuidade = (e) => {
  //   e.preventDefault()
  //   swal.fire({
  //     title: `<h2 style='color:${colors.info900}'>Geração de Anuidades</h2>`,
  //     // html: modalGeracaoAnuidade(),
  //     icon: 'question',
  //     showCancelButton: true,
  //     confirmButtonColor: colors.success500,
  //     cancelButtonColor: colors.danger500,
  //     confirmButtonText: 'Sim',
  //     cancelButtonText: 'Não'
  //   }).then((result) => {
  //     if (result.value) {
  //       api.post('/associado_plano/geraAnuidade', {ano}).then(r => {
  //         console.log(r.data)
  //       })
  //     }
  //   })
  // }


  // filtros por ,  forma de pagamento, periodicidade 
  // ( mensal / anual - combo com os campos pgto_mensal, pgto_anual ou todos ), situacao do associado e ativo;
  return (
    <div className="Manut">
      <NavBar />
      <div className="ManutContent">
        <div className="defaultHeader">
          <h3>Associação</h3>
          <UserOptions temaEscuro={props.temaEscuro} setTemaEscuro={props.setTemaEscuro} />
        </div>
        <br />
        <div className="filtersDiv">
          <form id="searchForm" onSubmit={getData}>
            <div>
              <AutoComp
                value={filter.associado || ""}
                valor={filter.fk_ass || ''}
                width={425}
                name="fk_ass"
                id="fk_ass"
                label='Associado'
                data={comboAss}
                setValue={setAss}
              />
                

              <FrSelect
                value={filter.fk_tme || ''}
                style={{ width: 170 }}
                name="fk_tme"
                id="fk_tme"
                label='Tipo Mensalidade'
                data={comboTme}
                onChange={handleChange}
              />

              <>
                <FrDatePicker
                  value={(filter.data_associacao_ini || '').split('T')[0]}
                  name="data_associacao_ini"
                  id="data_associacao_ini"
                  label="Data de Associação"
                  color="primary"
                  variant="outlined"
                  size="small"
                  style={{ width: (190), flex: ('') }}
                  onChange={handleChange}
                />

                <label style={{ paddingRight: '7px', paddingTop: '7px' }}>a</label>

                <FrDatePicker
                  value={(filter.data_associacao_fim || '').split('T')[0]}
                  name="data_associacao_fim"
                  id="data_associacao_fim"
                  color="primary"
                  variant="outlined"
                  size="small"
                  style={{ width: 190, flex:  '' }}
                  onChange={handleChange}

                />
              </>
              <FrSelect
                value={filter.fk_fpg || ''}
                style={{ width: 170 }}
                name="fk_fpg"
                id="fk_fpg"
                label='Forma de Pagamento'
                data={comboFpg}
                onChange={handleChange}
              />

              {/* <FrSelect
                value={filter.peridiocidade || ''}
                style={{ width: 170 }}
                name="peridiocidade"
                id="peridiocidade"
                label='Peridiocidade'
                data={comboPeridiocidade}
                onChange={handleChange}
              /> */}

              <FrSelect
                value={filter.fk_sas || ''}
                style={{ width: 170 }}
                name="fk_sas"
                id="fk_sas"
                label='Situação Associado'
                data={comboSas}
                onChange={handleChange}
              />

              <FrSelect
                value={filter.situacao || ''}
                style={{ width: 170 }}
                name="situacao"
                id="situacao"
                label='Situação'
                data={comboSit}
                onChange={handleChange}
              />
              <br/>
               <>
                <FrDatePicker
                  value={(filter.data_cancelamento_ini || '').split('T')[0]}
                  name="data_cancelamento_ini"
                  id="data_cancelamento_ini"
                  label="Data de Cancelamento"
                  color="primary"
                  variant="outlined"
                  size="small"
                  style={{ width: (190), flex: ('') }}
                  onChange={handleChange}
                />

                <label style={{ paddingRight: '7px', paddingTop: '7px' }}>a</label>

                <FrDatePicker
                  value={(filter.data_cancelamento_fim || '').split('T')[0]}
                  name="data_cancelamento_fim"
                  id="data_cancelamento_fim"
                  color="primary"
                  variant="outlined"
                  size="small"
                  style={{ width: 190, flex:  '' }}
                  onChange={handleChange}

                />
              </>

            </div>
            <div>
              <button onClick={getData}>
                <Icon icon={ic_search} size={18} />
                Pesquisar
              </button>
              <button onClick={clearForm}>
                <Icon icon={ic_delete} size={18} />
                Limpar
              </button>
              {enableAdd !== 'S' ? '' :
                <Link to="/celg/associado_plano/registro">
                  <button >
                    <Icon icon={ic_add_box} size={18} />
                  Novo Registro
                </button>
                </Link>}
              <button onClick={mostraModal}>
                <Icon icon={ic_search} size={18} />
                Cancelar Plano
              </button>
                  {/* <button onClick={geraAnuidade}>
                    <Icon icon={ic_add_box} size={18} />
                  Novo Registro
                </button> */}
            </div>
            <div>
                  <Dialog aria-labelledby="simple-dialog-title" open={showModal} fullWidth={false} maxWidth={'lg'} >
                    <div style={{ padding: '20px', paddingTop: '40px', backgroundColor:'var(--cor-tema)'  }}>

                      <div className="defaultHeader">

                        <h3>Geração de Boleto</h3>
                      </div>
                      <br />


                      <div style={{ display: 'flex', flex: 1, marginTop: '20px', flexDirection: 'column', alignItems: 'flex-start', backgroundColor:'var(--cor-tema)' }}>
                        

                      <FrDatePicker
                          name="cancelamento"
                          id="cancelamento"
                          color="primary"
                          label="Data de Cancelamento"
                          variant="outlined"
                          size="small"
                          style={{ width: 190 }}
                          unclear={true}
                          value={dataCancelamento}
                          onChange={handleChangeModal}
                        />

                      <FrTextInput
                        maxLength='200'
                        value={motivo}
                        onChange={handleChangeModal}
                        name="motivo"
                        id="motivo"
                        color="#389492"
                        label="Motivo Cancelamento"
                        variant="outlined"
                        required
                        multiline
                        rows="3"
                        rowsMax="8"
                        style={{ width: 320 }}
                      />



                      </div>
                    </div>
                    <div className="FrModalFooter">
                      <div className="FrModalButtonsDiv" >
                        <button onClick={cancelarAssociacao}> Confirmar </button>
                        <button onClick={escondeModal}> Sair </button>
                      </div>
                    </div>
                  </Dialog>
                </div>
          </form>
        </div>
        <br />
        <div className="tableDiv">
          <FrTable regName="associado_plano" columns={columns} searched={filtered}  codeName="pk_asp" page={'Associado_Plano'} selected={selected} setSelected={setSelected} data={data} enableEdit={enableEdit} enableDel={enableDel} />
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Associado_Plano;
